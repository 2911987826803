import * as React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'

import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/700.css';
import './src/styles/default.css';

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)
